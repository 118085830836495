<template>
    <el-row class="paper" type="flex" align="center">
        <div class="paper-bar">
            <div class="paper-bar__content">
                <span @click="gotoXuexi">返回</span>
            </div>
        </div>
        <el-col class="paper-wrapper">
            <div class="paper-wrapper__title">
                <h3>{{ shijuanInfo.particular_year }} </h3>
                <h3>
                    {{ shijuanInfo.title }}
                </h3>
                <h3>
                    {{ shijuanInfo.deputy_title }}
                </h3>
                <div class="paper-wrapper__title--info">
                    <span>时间：{{ kaoshiInfo.exam_time }}分钟</span>
                    <span>满分：{{ kaoshiInfo.score }}分</span>
                    <span>
                        得分：<span>{{ score }}</span>
                    </span>
                </div>
            </div>
            <div class="paper-wrapper__content">
                <template v-for="(item, index) in examList">
                    <h3 v-if="item.level === 1 || item.level == 3">{{ item.title }}</h3>
                    <div class="topic">
                        <div class="topic-title">
                            <div class="topic-title__wrapper">
                                <span class="topic-title__text" v-if="item.level == 2">
                                    {{ item.title }}
                                </span>
                            </div>
                        </div>
                        <div class="topic-content">
                            <span class="topic-content__title" v-if="item.deputy_title">
                                {{ item.deputy_title }}
                            </span>
                            <template v-if="item.materials && item.is_end == 1">
                                <div v-if="item.materials.content">
                                    <div v-html="item.materials.content"></div>
                                </div>
                                <div v-if="item.materials.audio">
                                    <audio v-if="item.materials.audio != ''" id="audio" ref="audio" controls>
                                        <source :src="item.materials.audio | img">
                                        <source :src="item.materials.audio | img">
                                        您的浏览器不支持 audio 元素。
                                    </audio>
                                </div>
                                <div class="topic-content__img">
                                    <ul class="img-list">
                                        <li v-for="(c_item, c_index) in item.materials.images" :key="c_index">
                                            <img :src="c_item.url | img" alt="">
                                            <span>{{ c_index | getSerial }}</span>
                                        </li>
                                    </ul>
                                    <!--<ul class="answer-list">
                                        <li>
                                            <span>1.</span>
                                            <span>span</span>
                                        </li>
                                        <li>
                                            <span>1.</span>
                                            <span>B</span>
                                        </li>
                                        <li>
                                            <span>1.</span>
                                            <span>C</span>
                                        </li>
                                        <li>
                                            <span>1.</span>
                                            <span>D</span>
                                        </li>
                                        <li>
                                            <span>1.</span>
                                            <span>E</span>
                                        </li>
                                    </ul>-->
                                </div>
                            </template>
                            <template v-if="item.timu && item.is_end == 1">
                                <template v-for="(g_item, g_index) in item.timu">
                                    <span class="topic-content__title" v-if="g_item.title" :id="'timu_'+g_item.num">
                                        {{ g_item.num }}.
                                        <span v-html="g_item.title"></span>
                                    </span>
                                    <div class="topic-content__body" :key="g_index">
                                        <ul class="body-list1" v-if="g_item.type == 1 || g_item.type == 2">
                                            <li v-for="(h_item, h_index)  in g_item.value" :key="h_index">
                                                <span class="list-num same-num"
                                                    v-if="g_item.title === '' || g_item.title === null" :id="'timu_'+g_item.num">{{ g_item.num
                                                    }}.
                                                </span>
                                                <template v-for="(i_item, i_index) in h_item.item">
                                                    <span
                                                        :class="['list-answer', h_item.is_right == 1 && h_item.answer == i_item.num ? 'item-check' : '', h_item.is_right == 0 && h_item.answer == i_item.num ? 'item-err' : '']"
                                                        :key="i_index">
                                                        <span class="list-title same-title">{{ i_item.num | getSerial }}
                                                            <template v-if="i_item.value">.</template></span>
                                                        <span class="list-answer same-answer">{{ i_item.value }}</span>
                                                    </span>
                                                </template>

                                                <!--<span class="list-answer item-err">
                                                    <span class="list-title same-title">B.</span>
                                                    <span class="list-answer same-answer">shop</span>
                                                </span>
                                                <span class="list-answer">
                                                    <span class="list-title same-title">C.</span>
                                                    <span class="list-answer same-answer">shop</span>
                                                </span>
                                                <span class="list-answer">
                                                    <span class="list-title same-title">D.</span>
                                                    <span class="list-answer same-answer">shop</span>
                                                </span>-->
                                            </li>
                                        </ul>
                                        <ul class="body-list1" v-if="g_item.type == 3">
                                            <li>
                                                <template v-for="(j_item, j_index)  in judge_item">
                                                    <span :key="j_index"
                                                        :class="['list-answer', g_item.is_right == 1 && g_item.answer == j_item.id ? 'item-check' : '', g_item.is_right == 0 && g_item.answer == j_item.id ? 'item-err' : '']">
                                                        <span class="list-title same-title">{{ j_index |
                                                                getSerial
                                                        }}</span>
                                                        <span class="list-answer same-answer">{{ j_item.name }}</span>
                                                    </span>
                                                </template>
                                            </li>
                                        </ul>
                                        <template v-if="g_item.type == 4 || g_item.type == 5">
                                            <div v-html="g_item.answer" :class="['font_size_25',g_item.is_right?'font_green':'font_red',]"> </div>
                                        </template>
                                    </div>
                                    <template v-if="g_item.type == 1 || g_item.type == 2">
                                        <div class="topic-content__analysis" v-for="(h_item, h_index)  in g_item.value"
                                            :key="h_index">
                                            <div class="answer-right">
                                                <span class="answer-right__text" v-if="kaoshiInfo.is_show_answer==1">
                                                    正确答案: <span>{{ h_item.right_answer | getSerial }}</span>
                                                </span>
                                                <span class="answer-right__analysis" v-if="kaoshiInfo.is_show_analysis==1">
                                                    答案解析：{{ h_item.analysis }}
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-if="g_item.type == 3">
                                        <div class="topic-content__analysis">
                                            <div class="answer-right">
                                                <span class="answer-right__text" v-if="kaoshiInfo.is_show_answer==1">
                                                    正确答案:
                                                    <template v-for="(j_item, j_index) in judge_item">
                                                        <span :key="j_index" v-if="j_item.id == g_item.right_answer">{{
                                                                j_index | getSerial
                                                        }}</span>
                                                    </template>
                                                </span>
                                                <span class="answer-right__analysis" v-if="kaoshiInfo.is_show_analysis==1">
                                                    答案解析：{{ g_item.analysis }}
                                                </span>
                                            </div>
                                        </div>
                                    </template>

                                    <template v-if="g_item.type == 4 || g_item.type == 5">
                                        <div class="topic-content__analysis">
                                            <div class="answer-right">
                                                <span class="answer-right__text" v-if="kaoshiInfo.is_show_answer==1">
                                                    正确答案:{{ g_item.right_answer }}
                                                </span>
                                                <span class="answer-right__analysis" v-if="kaoshiInfo.is_show_analysis==1">
                                                    答案解析：{{ g_item.analysis }}
                                                </span>
                                            </div>
                                        </div>
                                    </template>

                                </template>

                            </template>
                        </div>
                    </div>
                    <div class="topic" style="display:none">
                        <div class="topic-title">
                            <div class="topic-title__wrapper">
                                <span class="topic-title__text">
                                    Ⅱ情景反应（共 5 小题，每小题 1 分，满分 5 分）
                                </span>
                            </div>
                        </div>
                        <div class="topic-content">
                            <span class="topic-content__title">
                                根据你所听到的句子，选出正确的答语。每个句子读两遍。
                            </span>
                            <div class="topic-content__body">
                                <ul class="body-list1">
                                    <li>
                                        <span class="list-num same-num">5. </span>
                                        <span class="list-answer item-check">
                                            <span class="list-title same-title">A.</span>
                                            <span class="list-answer same-answer">shop</span>
                                        </span>
                                        <span class="list-answer item-err">
                                            <span class="list-title same-title">B.</span>
                                            <span class="list-answer same-answer">shop</span>
                                        </span>
                                        <span class="list-answer">
                                            <span class="list-title same-title">C.</span>
                                            <span class="list-answer same-answer">shop</span>
                                        </span>
                                        <span class="list-answer">
                                            <span class="list-title same-title">D.</span>
                                            <span class="list-answer same-answer">shop</span>
                                        </span>
                                    </li>
                                </ul>
                            </div>
                            <div class="topic-content__analysis">
                                <div class="answer-right">
                                    <span class="answer-right__text">
                                        1. 正确答案: <span>A</span>
                                    </span>
                                    <span class="answer-right__analysis">
                                        1. 答案解析：小朋友在跳花绳，图中一共是 5 个人在跳绳，故选 D 。
                                    </span>
                                </div>
                                <div class="answer-right">
                                    <span class="answer-right__text">
                                        1. 正确答案: <span>A</span>
                                    </span>
                                    <span class="answer-right__analysis">
                                        1. 答案解析：小朋友在跳花绳，图中一共是 5 个人在跳绳，故选 D 。
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>     
            </div>
        </el-col>
        <el-col class="paper-answer">
            <div class="answer-sheet">
                <p class="answer-sheet__title">答题卡</p>
                <div class="answer-sheet__content">
                    <template v-for="(item, index) in examList">
                        <p class="answer-sheet__content--header" :key="index" v-if="item.level === 1">
                            {{ item.title }}
                        </p>
                        <div class="answer-sheet__content--body" :key="index" v-if="item.level == 2 || item.level == 3">
                            <p class="sheet-title">
                                {{ item.title }}
                            </p>
                            <div class="sheet-item">
                                <span
                                    :class="[timu_item.is_right == 1 ? 'item-check' : '', timu_item.is_right == 0 ? 'item-not' : '']"
                                    v-for="(timu_item, timu_index) in item.timu" :key="timu_index" @click="toLocal('timu_'+timu_item.num)">{{
                                            timu_item.num
                                    }}</span>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="answer-sheet__footer">
                    <div v-for="(item, index) in items" :key="index">
                        <span></span>
                        <span>{{ item }}</span>
                    </div>
                </div>
            </div>
        </el-col>
    </el-row>
</template>

<script>
let that;
let SERIAL_NUM = ["A", "B", "C", "D", "E", "F", "G"]; //选项编号
let JUDGE_ITEM = [{ id: 1, name: '正确' }, { id: 0, name: '错误' }]

export default {
    name: "paper_analysis",
    props: {
        kaoshiInfo: { type: Object, default: {} },
        shijuanInfo: { type: Object, default: {} },
        examList: { type: Array, default: [] },
        score: { type: Number }
    },
    data() {
        return {
            judge_item: JUDGE_ITEM,
            items: ['正确', '错误'],
        }
    },
    created() {
        console.log("kaoshiInfo",this.kaoshiInfo);
    },
    mounted(){
        //this.toLocal('test');
    },
    filters: {
        img: (val) => {
            // msg表示要过滤的数据，a表示传入的参数
            return that.$conf.storage_url + "/" + val;
        },
        getSerial(val) {
            return SERIAL_NUM[val];
        },
    },
    beforeCreate: function () {
        that = this;
    },
    methods: {
        gotoXuexi() {
            this.$emit('close');
        },
        toLocal (loc) {
            let toElement = document.getElementById(loc)
            toElement.scrollIntoView();
            //window.scrollTo(0, 200);
        }
    }
}
</script>

<style lang="scss" scoped>
.paper-wrapper__content .same-title {
    padding-right: 5px;
}

.answer-sheet__footer {
    div {
        &:first-child {
            span:first-child {
                background-color: #00bfa5 !important;
            }
        }

        &:last-child {
            span:first-child {
                background-color: #f16e6e !important;
            }
        }
    }
}
.font_green{
    color: #00bfa5;
}

.font_red{
    color: #f16e6e;
}

.font_size_25{
    font-size: 25px;
}



</style>